import React, { Component, createRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import encryptUrl from "../components/encrypt-url"

class NewspaperView extends Component {
  state = {
    loading: true,
  }
  iframe = createRef()
  sendToSiteOnError( iframeUrl ) {
    const link = document.createElement('a');
    link.href = iframeUrl;
    document.body.append(link);

    link.click();
  }
  render() {
    const { data } = this.props
    const iframeUrl = data.markdownRemark.frontmatter.embed ? data.markdownRemark.frontmatter.url : 'https://an-proxy.herokuapp.com/index.php?q=' + encryptUrl( data.markdownRemark.frontmatter.url );
    const title = data.markdownRemark.frontmatter.titleBn ? `${data.markdownRemark.frontmatter.title} (${data.markdownRemark.frontmatter.titleBn})` : data.markdownRemark.frontmatter.title;
    return (
      <Layout isIframe={true}>
        <SEO
          title={title}
          meta={[
            {
              name: "robots",
              content: "noindex",
            },
          ]}
        />
        <iframe
          title={title}
          ref={this.iframe}
          onLoad={(e) => {
            // const iframeDoc = this.iframe.current.contentDocument || this.iframe.current.contentWindow.document;
            setTimeout(() => {
              if (0 === this.iframe.current.contentWindow.length) {
                this.setState({
                  loading: true,
                });
                this.sendToSiteOnError( iframeUrl );
              }
            }, 500)
            this.setState({
              loading: false,
            });
          }}
          className="iframe-view"
          src={iframeUrl}
        />
        {this.state.loading && (
          <div className="iframe-preloader">
            <div className="iframe-preloader-inner">
              <svg
                width="287"
                height="287"
                viewBox="0 0 287 287"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M94.3612 162.333L129.613 102.274V162.333H94.3612Z"
                  fill="#17B84E"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M143.5 0C64.2471 0 0 64.2471 0 143.5C0 222.753 64.2471 287 143.5 287C222.753 287 287 222.753 287 143.5C287 64.2471 222.753 0 143.5 0ZM56.7778 198.083L127.361 78H148.279L206.226 163.514L214.625 179.558V89.8817L199.269 87.5401V78H241.945V87.5401L227.606 89.9685V210H215.05L155.83 123.185L142.594 100.029V198.118L158.29 200.46V210H115.445V200.46L129.613 198.118V173.333H89.7778L76.0278 198.083L87.9445 200.833V210H45.7778V200.833L56.7778 198.083Z"
                  fill="#17B84E"
                />
              </svg>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default NewspaperView

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        titleBn
        id
        url
        embed
      }
    }
  }
`
