const base64_url_encode = ( input ) => {
	// eslint-disable-next-line no-use-before-define
	if ( typeof btoa === 'undefined' ) {
		var btoa = require('btoa');
	}
	return btoa( input ).replace( /\+/g, '-' ).replace( /\//g, '_' ).replace( /(=|==)$/g, '' );
}

const str_rot_pass = ( str, key, decrypt = false ) => {
	key = key.split('');
	str = str.split( '' )
	const key_len = key.length;

	let result = ' '.repeat( str.length );

	result = result.split( '' );


	for( let i=0; i<str.length; i++){
		let ascii = '';
		if(decrypt){
			ascii =  str[i].charCodeAt(0) - key[i % key_len].charCodeAt(0);
		} else {
			ascii = str[i].charCodeAt(0) + key[i % key_len].charCodeAt(0);
		}

		if ( result.length > i ) {
			result[i] = String.fromCharCode(ascii);
		}

	}

	return result.join('');
}


const encryptUrl = ( url ) => {
	const key = 'C4wm4rut49H1AE4O883e0LZeNeaesE7E1OH';

	const hashedUrl = str_rot_pass( url, key );

	return base64_url_encode( hashedUrl );
}


export default encryptUrl;